import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {IMenuItem} from "../../../core/interfaces/IMenuItem";
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'weni-item-menu',
    templateUrl: './item-menu.component.html',
    styleUrls: ['./item-menu.component.less'],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class ItemMenuComponent implements OnInit {

  @Input("nzSourceImage") sourceImage!: string;
  @Input("nzLabel") label!: string;
  @Input("nzSubMenu") submenu: any[] = [];
  @Input("nzSubmenu") isSubmenu: boolean = false;
  @Input("nzOpen") openSubmenu: boolean = false;
  @Input("nzActive") active: boolean = false;

  ngOnInit(): void {

  }
}
