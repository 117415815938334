<div class="rounded-xl relative-container">
  <div class="container flex flex-col items-center">
    <div>
      <div class="line"></div>
      <div class="line d1"></div>
      <div class="line d2"></div>
      <div class="line d3"></div>
      <div class="line d4"></div>
      <div class="line d5"></div>
    </div>
    <div class="caption">
      <p style="display: inline-block">
        Récupération des données de la page en cour
      </p>
      <div class="dot">.</div>
      <div class="dot">.</div>
      <div class="dot">.</div>
    </div>
  </div>
</div>
