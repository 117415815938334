import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '../../core/interfaces/IMenuItem';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { mappingRoutes } from '../../core/mappingRoutes';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { PageLoadingWidgetComponent } from '../page-loading-widget/page-loading-widget.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ItemSubmenuComponent } from '../../shared/items/item-submenu/item-submenu.component';
import { ItemMenuComponent } from '../../shared/items/item-menu/item-menu.component';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@Component({
    selector: 'weni-layout-admin',
    templateUrl: './layout-admin.component.html',
    styleUrls: ['./layout-admin.component.less'],
    standalone: true,
    imports: [
        NzLayoutModule,
        NzSpaceModule,
        NzAvatarModule,
        NzDropDownModule,
        NzMenuModule,
        NzButtonModule,
        NgFor,
        ItemMenuComponent,
        ItemSubmenuComponent,
        NgClass,
        NzDividerModule,
        NzWaveModule,
        NzIconModule,
        NgIf,
        NzGridModule,
        NzFormModule,
        NzInputModule,
        PageLoadingWidgetComponent,
        NzBreadCrumbModule,
        RouterOutlet,
    ],
})
export class LayoutAdminComponent implements OnInit {
  isOpenNavbar: boolean = false;

  menus: IMenuItem[] = [
    {
      nzSourceImage: 'dashboard.svg',
      nzLabel: 'Tableau de bord',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.dashboard,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: 'two-bar.svg',
      nzLabel: 'Demande de paiements',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.paymentRequest.list,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: 'two-bar.svg',
      nzLabel: 'Administrateurs',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: false,
      pathRedirect: mappingRoutes.administrators.admins,
      nzSubmenuItem: [],
    },
    {
      nzSourceImage: 'add-to-cart.svg',
      nzLabel: 'Commandes',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Clients',
          active: true,
          pathRedirect: mappingRoutes.customerOrders.list,
        },
        {
          label: 'Fournisseurs',
          active: false,
          pathRedirect: mappingRoutes.supplierDelivery.list,
        },
        {
          label: 'Colis',
          active: false,
          pathRedirect: mappingRoutes.shipment.list,
        },
        {
          label: 'Localisation livreur',
          active: false,
          pathRedirect: mappingRoutes.shipment.driverGlobalLocation,
        },

        {
          label: 'Rapport',
          active: false,
          pathRedirect: mappingRoutes.report.list,
        },

        {
          label: 'Rapport journalier',
          active: false,
          pathRedirect: mappingRoutes.states.index,
        },
      ],
    },
    {
      nzSourceImage: 'user.svg',
      nzLabel: 'Gestion de compte',
      nzActive: false,
      nzOpen: true,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Boutiques',
          active: true,
          pathRedirect: mappingRoutes.manageAccount.shop,
        },
        {
          label: 'Fournisseurs',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.fournisseur.list,
        },
        {
          label: 'Commerciaux',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.sales,
        },
        {
          label: 'Logisticiens',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.logisticians,
        },
        {
          label: 'Livreurs',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.drivers,
        },
        {
          label: 'Utilisateurs mobiles',
          active: false,
          pathRedirect: mappingRoutes.manageAccount.customers.list,
        },
      ],
    },
    {
      nzSourceImage: 'moto.svg',
      nzLabel: 'Gestion des engins',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Engins',
          active: true,
          pathRedirect: mappingRoutes.equipments.engine,
        },
        {
          label: 'Historique',
          active: false,
          pathRedirect: mappingRoutes.equipments.histories,
        },
        {
          label: 'Sinistres',
          active: false,
          pathRedirect: mappingRoutes.equipments.claims,
        },
      ],
    },
    {
      nzSourceImage: 'security-control.svg',
      nzLabel: 'Sécurité',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Profils',
          active: true,
          pathRedirect: mappingRoutes.profiles.list,
        },
        {
          label: 'Permissions',
          active: false,
          pathRedirect: mappingRoutes.permissions.list,
        },
      ],
    },

    {
      nzSourceImage: 'package.svg',
      nzLabel: 'Référentiel',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Zones',
          active: true,
          pathRedirect: mappingRoutes.referential.area.list,
        },

        {
          label: 'Motif de relance',
          active: false,
          pathRedirect: mappingRoutes.referential.relaunch.list,
        },
        {
          label: 'Motif de retour',
          active: false,
          pathRedirect: mappingRoutes.referential.return.list,
        },
        {
          label: 'Marques',
          active: false,
          pathRedirect: mappingRoutes.referential.brands,
        },
        {
          label: 'Ville',
          active: false,
          pathRedirect: mappingRoutes.referential.city,
        },
        {
          label: 'Pays',
          active: false,
          pathRedirect: mappingRoutes.referential.countries,
        },
      ],
    },

    {
      nzSourceImage: 'question-circle.svg',
      nzLabel: 'FAQ',
      nzActive: false,
      nzOpen: false,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Catégories',
          active: true,
          pathRedirect: mappingRoutes.faq.categories.list,
        },

        {
          label: 'Questions',
          active: false,
          pathRedirect: mappingRoutes.faq.questions.list,
        },
      ],
    },

    {
      nzSourceImage: 'coins.svg',
      nzLabel: 'Services & tarifications',
      nzActive: false,
      nzOpen: true,
      nzSubmenu: true,
      nzSubmenuItem: [
        {
          label: 'Services livraison',
          active: false,
          pathRedirect: mappingRoutes.referential.deliveryService.list,
        },
        {
          label: 'Tarification zones',
          active: false,
          pathRedirect: mappingRoutes.tarifications.list,
        },
      ],
    },

    // {
    //   nzSourceImage: 'user.svg',
    //   nzLabel: 'Equipe',
    //   nzActive: false,
    //   nzOpen: false,
    //   nzSubmenu: false,
    //   nzSubmenuItem: [
    //     {
    //       label: 'Liste',
    //       active: true,
    //       pathRedirect: mappingRoutes.teams.list,
    //     },
    //     {
    //       label: 'Ajout',
    //       active: false,
    //       pathRedirect: mappingRoutes.teams.create,
    //     },
    //   ],
    // },
  ];
  isPageChange: boolean = false;
  constructor(private _router: Router, private _utilService: UtilsService) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isPageChange = true;
      } else if (event instanceof NavigationEnd) {
        this.isPageChange = false;
      }
    });
  }

  ngOnInit(): void {
    this.resetValue();
  }

  logout() {
    this._utilService.LogOut();
  }

  onRedirectTo(menu: IMenuItem) {
    this.menus = this.menus.map((value) => {
      return {
        ...value,
        nzActive: value.nzLabel == menu.nzLabel,
        nzOpen: value.nzLabel == menu.nzLabel && !value.nzOpen,
      };
    });
    if (menu.pathRedirect) {
      this._router.navigate([menu.pathRedirect]);
    } else if (menu.nzSubmenu && menu.nzSubmenuItem.length > 0) {
      // this._router.navigate([menu.nzSubmenuItem[0].pathRedirect]);
    }
  }

  goParameter() {
    this._router.navigate([mappingRoutes.settings]);
  }

  private resetValue() {
    this.menus = this.menus.map((value, index) => {
      return {
        ...value,
        nzActive: index == 0,
        nzOpen: index == 0 && value.nzSubmenu && value.nzSubmenuItem.length > 0,
        nzSubmenuItem: value.nzSubmenuItem.map((sub, key) => {
          return {
            ...sub,
            nzOpen: false,
            nzActive: false,
          };
        }),
      };
    });
  }

  goMyProfil() {
    this._router.navigate([mappingRoutes.myProfile]);
  }
  toggleNavbar() {
    this.isOpenNavbar = !this.isOpenNavbar;
  }

  goNotifications() {
    this._router.navigate([mappingRoutes.notifications]);
  }

  onInput(event: Event) {}
}
