<ng-template #IconUserCupertinos>
  <img alt="user" src="/assets/icons/search.svg" />
</ng-template>
<nz-layout class="!h-screen !w-full overflow-x-hidden">
  <nz-sider
    [nzCollapsed]="isOpenNavbar"
    nzBreakpoint="md"
    nzTheme="light"
    nzWidth="260px"
    [nzCollapsedWidth]="0"
  >
    <div class="content-sider">
      <div class="head-content-sider weni-mb-1">
        <div class="container-img-logo">
          <img
            alt="weni-logo"
            class="weni-w-100 weni-h-100 object-fit-contain"
            src="/assets/weni-logo.png"
          />
        </div>
        <nz-space
          class="nz-space weni-w-100 weni-my-1 weni-mb-0"
          nzDirection="horizontal"
          nzSize="middle"
          nzWrap
        >
          <nz-avatar
            *nzSpaceItem
            nzIcon="user"
            nzShape="circle"
            nzSize="large"
          ></nz-avatar>
          <div *nzSpaceItem class="weni-d-flex weni-flex-col">
            <h3 class="name-user">Weni Admin</h3>
            <span class="profile-user">Administrateur</span>
          </div>
          <span *nzSpaceItem>
            <a [nzDropdownMenu]="menu" nz-dropdown>
              <img
                alt="chevron"
                class="weni-w-100 weni-h-100 object-fit-contain"
                src="/assets/icons/chevron-up.svg"
              />
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item (click)="goMyProfil()">Mon profil</li>
              </ul>
            </nz-dropdown-menu>
          </span>
        </nz-space>
      </div>

      <div class="menu-content-sider">
        <div class="meu">
          <ng-container *ngFor="let menu of menus">
            <weni-item-menu
              (click)="onRedirectTo(menu)"
              [nzActive]="menu.nzActive"
              [nzLabel]="menu.nzLabel"
              [nzOpen]="menu.nzOpen"
              [nzSourceImage]="menu.nzSourceImage"
              [nzSubmenu]="menu.nzSubmenu"
            >
            </weni-item-menu>
            <weni-item-submenu
              [ngClass]="[
                menu.nzOpen && menu.nzSubmenu
                  ? 'visible-submenu'
                  : 'hide-submenu'
              ]"
              [nzSubmenuItem]="menu.nzSubmenuItem"
            ></weni-item-submenu>
          </ng-container>
          <nz-divider></nz-divider>
          <weni-item-menu
            (click)="goParameter()"
            [nzSourceImage]="'setting.svg'"
            nzLabel="Paramètres"
          ></weni-item-menu>
          <nz-divider></nz-divider>

          <div class="weni-p-1 weni-w-100">
            <button
              nzType="primary"
              nzDanger
              nz-button
              nzBlock
              nzShape="round"
              nzSize="large"
              (click)="logout()"
            >
              <span nz-icon nzType="logout"></span>
              Me déconnecter
            </button>
          </div>
        </div>
      </div>
    </div>
  </nz-sider>
  <div class="w-full px-4 overflow-y-auto">
    <nz-content>
      <div class="!overflow-x-hidden" style="height: 100%; width: 100%">
        <div
          class="bg-white rounded-[10px] shadow-sm p-4 mb-6 flex items-center justify-between"
        >
          <div class="weni-d-flex weni-items-center weni-gap-0">
            <ng-container *ngIf="!isOpenNavbar">
              <span
                (click)="toggleNavbar()"
                nz-icon
                class="menu-icon"
                nzType="menu-fold"
                nzTheme="outline"
              ></span
            ></ng-container>
            <ng-container *ngIf="isOpenNavbar">
              <span
                (click)="toggleNavbar()"
                nz-icon
                nzType="menu-unfold"
                nzTheme="outline"
                class="menu-icon"
              ></span>
            </ng-container>

            <div class="top-search-bar">
              <nz-input-group
                class="!rounded-[10px]"
                nzPrefixIcon="search"
                nzSize="large"
              >
                <input nz-input nzSize="large" placeholder="Recherche..." />
              </nz-input-group>
            </div>
          </div>

          <div class="relative mr-6">
            <button
              (click)="goNotifications()"
              class="relative p-2 transition rounded-full hover:bg-gray-200"
            >
              <!-- Icône de cloche -->

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-bell-dot"
              >
                <path d="M10.268 21a2 2 0 0 0 3.464 0" />
                <path
                  d="M13.916 2.314A6 6 0 0 0 6 8c0 4.499-1.411 5.956-2.74 7.327A1 1 0 0 0 4 17h16a1 1 0 0 0 .74-1.673 9 9 0 0 1-.585-.665"
                />
                <circle cx="18" cy="8" r="3" />
              </svg>

              <!-- Badge de notification -->
              <span
                class="absolute flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full -top-1 -right-1 animate-pulse"
              >
                0
              </span>
            </button>
          </div>
        </div>

        <div class="bg-white rounded-[10px] shadow-sm p-4">
          <ng-container>
            <div class="mb-1" style="margin-bottom: 1rem !important">
              <nz-breadcrumb
                class="weni-mb-1"
                nzAutoGenerate
                nzSeparator="/"
              ></nz-breadcrumb>
            </div>
            <div class="bg-[#f8f8f8] p-4 rounded-md">
              <ng-container *ngIf="isPageChange">
                <weni-page-loading-widget></weni-page-loading-widget>
              </ng-container>
              <ng-container *ngIf="!isPageChange">
                <router-outlet></router-outlet>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </nz-content>
  </div>
</nz-layout>
